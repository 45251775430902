<template>
  <div class="about-us">
    <section class="about-us__00">
      <h1 class="about-us__00-title desktop-only">Company History</h1>
    </section>
    <div class="togas-container grid">
      <div class="col-m-1 desktop-only">
        <div class="about-us__timeline">
          <svg class="about-us__timeline" width="56" height="553" viewBox="0 0 56 553" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <line x1="49.5" y1="9" x2="49.5" y2="545" stroke="#686864" />
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 1 }" cx="49.5" cy="10.5" r="6"
                    @click="handleTimelineClick('01')" />
            <text class="about-us__timeline-text" x="2" y="16">1926</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 2 }" cx="49.5" cy="76.5" r="6"
                    @click="handleTimelineClick('02')" />
            <text class="about-us__timeline-text" x="2" y="81">1945</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 3 }" cx="49.5" cy="144.5"
                    r="6" @click="handleTimelineClick('03')" />
            <text class="about-us__timeline-text" x="2" y="150">1970</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 4 }" cx="49.5" cy="211.5"
                    r="6" @click="handleTimelineClick('04')" />
            <text class="about-us__timeline-text" x="2" y="217">1995</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 5 }" cx="49.5" cy="278.5"
                    r="6" @click="handleTimelineClick('05')" />
            <text class="about-us__timeline-text" x="2" y="284">2000</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 6 }" cx="49.5" cy="345.5"
                    r="6" @click="handleTimelineClick('06')" />
            <text class="about-us__timeline-text" x="2" y="351">2012</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 7 }" cx="49.5" cy="412.5"
                    r="6" @click="handleTimelineClick('07')" />
            <text class="about-us__timeline-text" x="2" y="418">2016</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 8 }" cx="49.5" cy="479.5"
                    r="6" @click="handleTimelineClick('08')" />
            <text class="about-us__timeline-text" x="2" y="485">2021</text>
            <circle class="about-us__timeline-dot" :class="{'--active': activeSection === 9 }" cx="49.5" cy="546.5"
                    r="6" @click="handleTimelineClick('09')" />
            <text class="about-us__timeline-text" x="2" y="552">2024</text>
          </svg>
        </div>
      </div>
      <div class="col-m-11">
        <section
          class="about-us__container about-us__01 grid"
          id="1"
          v-observe-visibility="{
            callback: handleVisibility,
            intersection: {
              rootMargin: '0px',
              threshold: 0.8
            }
          }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">1926</h2>
            <h3 class="about-us__section-subtitle">How it all began</h3>
            <p class="about-us__section-text">In the heart of Athens, a young talented tailor named Ilias Togas embarks
              on a journey that weaves a tapestry of elegance and artistry. His small atelier is the birthplace of a
              dream, where the threads of craftsmanship and innovation intertwine. </p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/01-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/01-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__02 grid"
          id="2"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">1945</h2>
            <h3 class="about-us__section-subtitle">Legend is born</h3>
            <p class="about-us__section-text">As World War II ends Ilias opens retail shops in addition to his atelier.
              With each stitch, Ilias crafts more than mere textiles; he creates custom decorative textiles –
              bedspreads, curtains, table linens and pillows that adorn homes with beauty and sophistication. And so,
              the company known as Togas House of Textiles is established.</p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/02-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/02-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__03 grid"
          id="3"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">1970</h2>
            <h3 class="about-us__section-subtitle">Succession of generations</h3>
            <p class="about-us__section-text">The mantle of leadership passes to Ilias's eldest son, Pavlos Togas, who
              carries the torch with unwavering dedication. Inheriting his father's unique knowledge and the factory's
              boundless potential, Pavlos weaves a new chapter in the Togas history. Signature collections of
              embroidered decorative home textiles emerge, each piece a testament to the brand's commitment to precious
              fabrics and sophisticated decorations. Greece embraces Togas as a symbol of luxury and refinement.</p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/03-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/03-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__04 grid"
          id="4"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                root: document ? document.body : null,
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">1995</h2>
            <h3 class="about-us__section-subtitle">First Togas Boutique in the Global Market</h3>
            <p class="about-us__section-text">Togas, the grandson of the visionary founder, initiates international
              expansion of the company. He carries the Togas name across borders, captivating Europe with its exquisite
              wares. </p>
            <p class="about-us__section-text">In the heart of Moscow, at the same time, the first Togas mono brand
              boutique opens its doors at the iconic GUM, offering Russian customers a glimpse into the world of Greek
              artisanship.</p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/04-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/04-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__05 grid"
          id="5"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">2000</h2>
            <h3 class="about-us__section-subtitle">Togas Couture Atelier</h3>
            <p class="about-us__section-text">The first Togas Couture Atelier opens its doors in Moscow. A sanctuary of
              elegance, it beckons discerning clients with its magnificent collection of exquisite fabrics and an
              individualized approach. Here, dreams take shape as curtains, bedspreads, decorative pillows, and interior
              decor items are meticulously crafted, each a masterpiece in its own right. </p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/05-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/05-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__06 grid"
          id="6"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">2012</h2>
            <h3 class="about-us__section-subtitle">The Creative soul in Athens</h3>
            <p class="about-us__section-text">In the center of Athens, a new flame is kindled – the foundation of Togas'
              own creative office. A team of highly skilled European designers breathes life into the company's creative
              soul, where ideas for the Couture Atelier are born, and new patterns and fabric palettes blossom into
              reality. With each stroke of their brushes, the designers handcraft prints destined for future
              collections. </p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/06-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/06-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__07 grid"
          id="7"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">2016</h2>
            <h3 class="about-us__section-subtitle">Togas. Current history</h3>
            <p class="about-us__section-text">The world takes notice as Togas unveils its flagship boutique on the
              iconic 5th Avenue in New York City. Brand exposure widens, and new markets beckon, as the finished product
              range soars beyond 3,000 items. Embracing environmental responsibility, Togas adopts sustainable
              manufacturing concepts, ensuring that its legacy will be woven into the fabric of a greener tomorrow.</p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/07-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/07-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__08 grid"
          id="8"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">2021</h2>
            <h3 class="about-us__section-subtitle">Focus on Healthy Sleep for Adults and Children </h3>
            <p class="about-us__section-text">Togas unveils a sanctuary dedicated to the art of healthy sleep. The
              world's first Togas Kids boutique opens its doors, offering a haven where parents can find all manner of
              children's textiles – from bed linens and terry towels to pillows, duvets, and throws. But the true
              masterpiece is the Togas Innovations Sleep System, a holistic approach to restful slumber that combines
              the brand's finest offerings: beds, mattresses, mattress covers, pillows, and duvets, all designed to
              cradle dreams in luxurious comfort.</p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/08-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/08-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
        <section
          class="about-us__container about-us__09 grid"
          id="9"
          v-observe-visibility="{
              callback: handleVisibility,
              intersection: {
                rootMargin: '0px',
                threshold: 0.8
              }
            }"
        >
          <div class="about-us__container-text col-12 col-m-6">
            <h2 class="about-us__section-title">2024</h2>
            <h3 class="about-us__section-subtitle">Togas is a renowned expert in home textiles and décor.</h3>
            <p class="about-us__section-text">As we gaze into 2024, Togas stands as a renowned expert in home textiles
              and decor, a global force that has woven its legacy into the fabric of more than 18 countries across
              Europe, Asia, and America. With manufacturing sites that span the globe, each adhering to the strictest
              environmental controls, Togas continues to create unique home textile collections using only the finest
              fabrics and materials. A testament to enduring craftsmanship, unwavering vision, and a commitment to
              excellence that transcends generations</p>
          </div>
          <div class="about-us__container-image col-12 col-m-6">
            <picture>
              <source
                media="screen and (min-width: 1024px)"
                srcset="~/assets/images/about-us/09-d.webp"
              />
              <img class="about-us__section-image img-block" src="~/assets/images/about-us/09-m.webp" alt="About Us" />
            </picture>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api';
import { ObserveVisibility } from 'vue-observe-visibility';
import Vue from 'vue';

Vue.directive('observe-visibility', ObserveVisibility);

export default {
  name: 'AboutUs',
  head() {
    return {
      title: 'About Us'
    };
  },
  setup() {
    const activeSection = ref(0);

    const handleVisibility = (isVisible, entry) => { if(isVisible) activeSection.value = +entry.target.id; };

    const handleTimelineClick = (el) => {
      window.scrollTo({
        top: document.querySelector(`.about-us__${el}`).offsetTop - document.querySelector('header').offsetHeight,
        behavior: 'smooth'
      });
    };

    return {
      activeSection,
      handleVisibility,
      handleTimelineClick
    };
  }
};
</script>

<style lang="scss" scoped>
.about-us {
  background-color: var(--togas-black-color);
  position: relative;

  &__timeline {
    height: 553px;
    width: 56px;
    position: sticky;
    top: calc((100% - 553px) / 2);
  }

  &__timeline-dot {
    cursor: pointer;
    fill: #0F1319;
    stroke: #F5F5F5;

    &.--active,
    &:hover {
      fill: #F5F5F5;
      stroke: none;

      & + .about-us__timeline-text {
        fill: #F5F5F5;
      }
    }
  }

  &__timeline-text {
    font-family: var(--font-family--secondary);
    font-size: var(--font-size--sm);

    &.--active {
      fill: #F5F5F5;
    }
  }

  &__container {
    margin-bottom: 16px;
  }

  &__section-title {
    color: #F5F5F5;
    font: 400 102px / 112px var(--font-family--secondary);
    margin: 0 0 2px;

    @include for-mobile {
      text-align: right;
    }
  }

  &__section-subtitle {
    color: #F5F5F5;
    font: 700 24px / 30px var(--font-family--secondary);
    margin: 0 0 8px;
  }

  &__section-text {
    color: #F5F5F5;
    font: 18px / 24px var(--font-family--primary);

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__section-image {
    padding: 28px 14px 0;
  }

  &__00 {
    aspect-ratio: 375 / 211;
    background-image: url("~/assets/images/about-us/00-m.webp");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    &-title {
      color: #F5F5F5;
      font: 62px / 80px var(--font-family--secondary);
    }
  }

  @include for-desktop {

    &__container {
      margin-bottom: 100px;
    }

    &__container-image {
      position: relative;
    }

    &__container-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }


    &__section-image {
      padding: 0;
    }

    &__section-title {
      color: #FFF;
      font: 400 190px / 210px var(--font-family--secondary);
      margin: 0 0 32px;
      position: relative;

      &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: var(--togas-grey-600-color);
      }
    }

    &__section-subtitle {
      color: #FFF;
      font: 500 42px / 52px var(--font-family--secondary);
      margin: 0 0 16px;
    }

    &__section-text {
      color: #FFF;
    }

    &__00 {
      aspect-ratio: 1366 / 614;
      background-image: url("~/assets/images/about-us/00-d.webp");
      margin-bottom: 60px;
    }

    &__02 .about-us__container-text,
    &__04 .about-us__container-text {
      margin-bottom: 54px;
    }

    &__03 .about-us__container-text {
      margin-bottom: 68px;
    }

    &__05 .about-us__container-text {
      margin-bottom: 160px;
    }

    &__07 .about-us__container-text {
      margin-bottom: 100px;
    }

    &__08 .about-us__container-text {
      margin-bottom: 40px;
    }
    &__01,
    &__03,
    &__05,
    &__07,
    &__09 {
      .about-us__container-text {
        padding-right: 48px;
      }
    }

    &__02,
    &__04,
    &__06,
    &__08 {

      .about-us__section-title::after {
        right: 0;
        left: auto;
      }

      .about-us__container-image {
        order: -1;
      }

      .about-us__container-text {
        padding-left: 48px;
      }
    }
  }
}
</style>
